import styled, { createGlobalStyle } from 'styled-components';
import * as Constants from './constants';

const dimensions = Constants.componentDimensions;

export const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;

    h1, h2, h3, h4, p {
      font-family: ${Constants.fonts.serif};
    }
  }
`

export const App = styled.div`
  display: grid;
  font-family: ${Constants.fonts.sansSerif};
  width: 100vw;
  height: 100vh;
  height: 100dvh;
  overflow: hidden;
  background-color: ${Constants.colors.background};
  grid-template-columns: 25vw 25vw calc(50vw - ${dimensions.menuToggle.width.mobile}px) ${dimensions.menuToggle.width.mobile}px;
  grid-template-rows: ${dimensions.masthead.height.mobile}px calc(100vh - ${dimensions.masthead.height.mobile}px);
  grid-template-areas:
    "masthead   masthead   masthead  menutoggle"
    "main       main       main      main";

  @media ${Constants.devices.tablet} {
    grid-template-columns: 50vw calc(50vw - ${dimensions.menuToggle.width.tablet}px) ${dimensions.menuToggle.width.tablet}px;
    grid-template-rows: ${dimensions.masthead.height.tablet}px calc(100vh - ${dimensions.masthead.height.tablet}px);
    grid-template-areas:
      "masthead   masthead   menutoggle"
      "main       main       main";
  }

  @media ${Constants.devices.desktop} {
    grid-template-columns: min(33vw, 500px) auto;
    grid-template-rows: ${dimensions.masthead.height.desktop}px ${dimensions.menuToggle.height}px calc(100vh - ${dimensions.masthead.height.desktop}px - ${dimensions.menuToggle.height}px);
    grid-template-areas:
      "masthead   main"
      "menu       main"
      "menu       main";
  }

  #facets {
    grid-column: 1 / span 1;
    grid-row: 2 / span 2;
    height: calc(100vh - 150px);
    width: min(500px, 33vw);
    overflow-y: auto;
  }

  #adImage,
  #areaDescriptions {
    grid-column: 2 / span 2;
    grid-row: 1 / span 3;
    height: 100vh;
    z-index: 0;
  }

  // #mapSelectionControl {
  //   grid-column: 2 / span 1;
  //   grid-row: 1 / span 1;
  //   z-index: 2000;
  // }

  #adCategory {
    grid-column: 1 / span 3;
    grid-row: 2 / span 2;
  }

  .text {
    grid-column: 2 / span 2;
    grid-row: 1 / span 3;
  }
  // button.unMinimizeDataview {
  //   grid-column: 1 / span 1;
  //   grid-row: 3 / span 1;
  //   z-index: 2000;
  // }

  a {
    text-decoration: none;
  }
`;

export const Main = styled.main`
  grid-area: main;
  height: 100%;
  width: 100vw;

  @media ${Constants.devices.desktop} {
    width: calc(100vw - min(33vw, 500px));
    height: 100vw;
  }
`;
