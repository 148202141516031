import { Link, useParams } from "react-router-dom";
import { useIssue } from "../../hooks";
import IssueHeader from './Header/Index';
import Input from '../Search/Input/Index';
import * as Styled from './styled';

const Issue = () => {
  const { year, month, day } = useParams() as { year: string; month: string; day: string; };
  const articles = useIssue(parseInt(year), parseInt(month), parseInt(day));

  return (
    <Styled.Issue>
      <IssueHeader includeNav={true} />

      <Input placeholder="Search this issue" />

      <Styled.Articles>
        {articles.map((article) => {
          return (
            <Styled.Article key={article.article_id}>
              <Link to={`/${year}/${month}/${day}/${article.page}/${article.article_id}`}>
                <h2>{article.title}</h2>
                <Styled.Length>{`${article.type.toUpperCase()} • ${article.length.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} words`}</Styled.Length>
                <Styled.Excerpt>{article.excerpt}</Styled.Excerpt>
              </Link>
            </Styled.Article>
          );
        })}
      </Styled.Articles>
    </Styled.Issue>
  );
};

export default Issue;
