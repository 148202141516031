import { Link, useParams } from "react-router-dom";
import { getDayOfWeekName, getMonthName } from "../../../utilities";
import Issues from "../../../data/issues.json";
import * as Styled from "./styled";

const Issue = ({ link, includeNav }: { link?: boolean; includeNav?: boolean }) => {
  const { year, month, day } = useParams() as { year: string; month: string; day: string };
  const issueIdx = Issues.findIndex((issue) => issue.year === parseInt(year) && issue.month === parseInt(month) && issue.day === parseInt(day));
  const prevIssue = issueIdx !== -1 ? Issues[issueIdx - 1] : undefined;
  const nextIssue = issueIdx !== -1 ? Issues[issueIdx + 1] : undefined;

  if (includeNav) {
    return (
      <Styled.IssueHeader>
        {prevIssue && <Styled.Previous to={`/${prevIssue.year}/${prevIssue.month}/${prevIssue.day}`}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 166.52 331.63">
          <path d="M165.82 331.28.35 165.82 165.82.35" />
        </svg></Styled.Previous>}
        <Styled.Header>
          {`${getDayOfWeekName(parseInt(year), parseInt(month), parseInt(day))}, ${getMonthName(parseInt(month))} ${day}, ${year}`}
        </Styled.Header>
        {nextIssue && <Styled.Next to={`/${nextIssue.year}/${nextIssue.month}/${nextIssue.day}`}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 166.52 331.63">
          <path d="m.35 331.28 165.47-165.46L.35.35" />
        </svg></Styled.Next>}
      </Styled.IssueHeader>
    );
  }

  if (link) {
    return (
      <Styled.IssueHeader >
        <Styled.UpLink to={`/${year}/${month}/${day}`}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 166.52 331.63">
          <path d="m.35 331.28 165.47-165.46L.35.35" transform="rotate(270 83.25 166)" />
        </svg></Styled.UpLink>
        <Styled.Header>
          <Link to={`/${year}/${month}/${day}`}>{`${getDayOfWeekName(parseInt(year), parseInt(month), parseInt(day))}, ${getMonthName(parseInt(month))} ${day}, ${year}`}</Link>
        </Styled.Header>
      </Styled.IssueHeader>
    );
  }

  return <Styled.IssueHeader><Styled.Header>{`${getDayOfWeekName(parseInt(year), parseInt(month), parseInt(day))}, ${getMonthName(parseInt(month))} ${day}, ${year}`}</Styled.Header></Styled.IssueHeader>;
};

export default Issue;
