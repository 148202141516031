import { Link } from 'react-router-dom';
import * as Styled from './styled';

const Header = () => {
  
  return (
    <Styled.Header>
      <Link to='/'>
        <Styled.Title>Daily Dispatch</Styled.Title>
        <Styled.Subtitle>1860-1865</Styled.Subtitle>
      </Link>
    </Styled.Header>
  )
}

export default Header;