import styled from "styled-components";
import * as Constants from "../../constants";

export const Issue = styled.div`
  width: min(94%, 900px);
  padding: 3rem max(3%, calc((100% - 900px) / 2)) 0;
  height: calc(100% - 3em);
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 70px 70px auto;
  grid-template-areas: "header" "search-nav" "articles";

  height: calc(100dvh - 50px);

  @media ${Constants.devices.desktop} {
    height: 100vh;
  }
`;

export const Search = styled.input`
  display: block;
  margin: 10px auto;
`;

export const Length = styled.div`
  color: ${Constants.colors.textLight};
  text-align: left;
  font-size: 0.9em;
`;

export const Excerpt = styled.div`
  color: ${Constants.colors.text};
  display: block;
  display: -webkit-box;
  max-width: 100%;
  height: 48px;
  margin: 0 auto;
  font-size: 16px;
  line-height: 1.5;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-top: 20px;
`;

export const Articles = styled.ul`
  grid-area: articles;
  overflow: auto;
  padding: 0;
`;

export const Article = styled.li`
  border-bottom: 1px solid ${Constants.colors.grayMedium};
  list-style: none;

  a {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    text-decoration: none;
    padding: 1.5rem 0;
    color: currentColor;

    h2 {
      white-space: nowrap;  
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      line-height: 0.8;
      height: 25px;
      margin: 0 0 0.2em 0;
      font-family: ${Constants.fonts.serif};
      font-size: clamp(1rem, 2vw, 1.5rem);
    }
  }

  &:hover,
  &:active {

    h2, ${Length} {
      color: ${Constants.colors.interactive};
    }

    h2 {
      text-decoration: underline;
    }

    // h2,
    // ${Excerpt}, ${Length} {
    //   color: ${Constants.colors.interactive};
    // }
  }
`;