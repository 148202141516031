import { useState } from 'react';
import IssueHeader from '../Issue/Header/Index';
import Article from './Article/Index';
import Nav from './Nav/Index';
import ScanViewer from './ScanViewer/Index';
import * as Styled from './styled';

function PageViewer() {
  const [isTranscript, setIsTranscript] = useState(true);

  return (
    <Styled.PageViewer>
      <IssueHeader link={true} />

      <Nav
        isTranscript={isTranscript}
        setIsTranscript={setIsTranscript}
      />

      {(isTranscript) ? <Article /> : <ScanViewer />}

    </Styled.PageViewer>
  );
}

export default PageViewer;
