import { useParams } from "react-router-dom";
import * as Styled from "./styled";
import Calendar from '../../Calendar/Index';
import type { Year, Month } from "../../../index.d";

const Landing = () => {
  const { year } = useParams();

  const years: Year[] = [1860, 1861, 1862, 1863, 1864, 1865];
  const monthsWithIssues: { [key in Year]: Month[] } = {
    1860: [11, 12],
    1861: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
    1862: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
    1863: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
    1864: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
    1865: [1, 2, 3, 4],
  };

  return (
    <div>
        <h2>Browse the <cite>Dispatch</cite></h2>

        <Styled.Years>
          <Styled.YearLink to="/">All</Styled.YearLink>
          {years.map(year => (
            <Styled.YearLink to={`/${year}`} key={`linkTo${year}`}>{year}</Styled.YearLink>
          ))}
        </Styled.Years>
        {years.filter(y => !year || y === parseInt(year)).map(year => (
          <Styled.Year year={year} months={monthsWithIssues[year]} key={`container for ${year}`} >
            {monthsWithIssues[year].map(month => (
              <Calendar
                year={year}
                month={month}
                key={`${year}-${month}`}
              />
            ))}
          </Styled.Year>
        ))}
    </div>
  );
};

export default Landing;
