import styled from 'styled-components';
import * as Constants from '../../constants';
import { hexToRgb } from "../../utilities";

export const Text = styled.article`
  grid-area: main;
  height: calc(100vh - 6rem);
  padding: 3rem calc((100% - min(900px, 95%)) / 2);
  color: ${Constants.colors.text};
  // font-family: ${Constants.fonts.serif};
  font-weight: 300;
  line-height: 1.7;
  overflow-y: auto;

  * {
    -webkit-transition: all 0.25s ease;
    -moz-transition: all 0.25s ease;
    -o-transition: all 0.25s ease;
    transition: all 0.2s ease-in-out;
  }

  a {
    color: ${Constants.colors.interactive};
    border-bottom: 1px solid currentColor;

    &:hover,
    &:focus,
    &:active {
      border-bottom: 2px solid ${Constants.colors.interactive};
    }

    &[grade] {
      border-bottom: none;
    }
  }

  h2, h3 {
    text-align: center;
  }

  h2:not(:first-of-type) {
    margin-top: 3em;
  }

  ol, ul {
    margin-bottom: 2em;
    padding: 0 0 0 14px;

    li {
      margin-bottom: 0.7em;
      padding-left: 2px;
    }
  }

  > img {
    max-width: 100%;
  }

`;

export const Funders = styled.div`
  display: flex;
  gap: 20px;
  flex-wrap: wrap;

  a {
    text-decoration: none !important;
    border: none;
  }
`;

export const DownloadButton = styled.a`
  display: block;
  margin: 0 0 1rem 0;
  padding: 1em 2em;
  font-size: 0.8em;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  color: ${Constants.colors.white} !important;
  font-family: ${Constants.fonts.sansSerif};
  background-color: ${Constants.colors.interactive};
  border: none !important;

  @media ${Constants.devices.tablet} {
    flex-basis: 50%;
  }

  &:hover,
  &:focus,
  &:active {
    background-color: ${Constants.colors.interactiveDark};
  }

  svg {
    height: 1em;
    width: 1em;
    margin-right: 0.5em;
    vertical-align: text-top;

    &, * { transition: none; }
    
    line {
      stroke: currentColor !important;
    }
  }
`;

export const DownloadList = styled.ul`
  display: flex;
  flex-direction: column;
  justify-content: center;
  list-style-type: none;
  margin: 0 !important;
  padding: 0 !important;

  li {

    a {
      display: block;
      padding: 0.5em 0.1em;
      text-align: center;
      font-family: ${Constants.fonts.sansSerif};
      text-decoration: none;
      background-color: rgba(${hexToRgb(Constants.colors.white)}, 0.4);
      border: none !important;
      box-shadow: 0px 5px 5px -2px rgba(${hexToRgb(Constants.colors.black)}, 0.1);
      transition: background-color 300ms, box-shadow 1000ms;

      &:hover,
      &:active {
        background-color: rgba(${hexToRgb(Constants.colors.white)}, 1);
        box-shadow: 0px 5px 5px -2px rgba(${hexToRgb(Constants.colors.black)}, 0.025);
      }
    }

  }

  @media ${Constants.devices.tablet} {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;

    li {
      flex-basis: calc(50% - 5px);
    }
  }

  @media ${Constants.devices.desktop2} {

    li {
      flex-basis: calc(33.3% - 5px);
    }
  }
`;