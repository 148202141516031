const Download = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
      <g transform="rotate(90 10 10)">
        <g transform="translate(10 10)">
          <line x1="-6.5" x2="6" y1="0" y2="0" stroke-width="1.5"></line>
          <g transform="rotate(45 6.25 0)">
            <line x1="1" x2="7" y1="0" y2="0" stroke-width="1.5"></line>
            <line x1="6.25" x2="6.25" y1="-0.75" y2="5.25" stroke-width="1.5"></line>
          </g>
        </g>
      </g>
      <g transform="translate(10 10)">
        <line x1="-7" x2="7" y1="8.5" y2="8.5" stroke-width="1.5"></line>
      </g>
    </svg>
  )
};

export default Download;