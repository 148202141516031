import styled from 'styled-components';
import * as Constants from '../../constants';

export const Nav = styled.ul`
  grid-area: main-start / 1 / -1 / -1;
  height: 100%;
  //width: calc(min(500px, 33vw) - 200px);
  width: 100%;
  overflow-y: auto;
  margin: 0;
  padding: 0;
  justify-self: end;
  background-color: ${Constants.colors.text};
  font-family: ${Constants.fonts.serif};
  font-size: 17px;
  font-weight: 300;
  z-index: 5001;
  box-shadow: 0px 3px 6px 3px rgba(0, 0, 0, 0.5);
  // hide the scrollbar
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */

  ::-webkit-scrollbar { 
      display: none;  /* Safari and Chrome */
  }

  @media ${Constants.devices.tablet} {
    grid-area: main-start / 2 / main-end / main-end;
  }

  @media ${Constants.devices.desktop} {
    position: relative;
    right: auto;
    grid-area: menu;
    font-size: 18px;
    z-index: 0;
    box-shadow: none;
  }


`;

export const Item = styled.li`
  position: relative;
  margin: 1.5rem 0;
  list-style: none;
  color: ${Constants.colors.background};
  text-align: right;
  line-height: 1.2;

  a {
    position: relative;
    display: inline-block;
    padding-bottom: 5px;
    padding-right: 20px;
    color: ${Constants.colors.grayMedium};

    &:after {
      content: '';
      position: absolute;
      left: 0;
      width: 100%;
      transform: scaleX(0);
      height: 2px;
      bottom: 0;
      background-color: ${Constants.colors.interactive};
      transform-origin: bottom right;
      transition: transform 0.25s ease-out;
    }

    &:hover:after,
    &:focus:after,
    &:active:after,
    &.active:after {
      transform: scaleX(1);
      transform-origin: bottom left;
    }

    * {
      -webkit-transition: all 0.25s ease;
      -moz-transition: all 0.25s ease;
      -ms-transition: all 0.25s ease;
      -o-transition: all 0.25s ease;
      transition: all 0.25s ease;
    }

    &:hover,
    &:focus {
      color: ${Constants.colors.interactiveLight};
    }
    &.active {
      color: ${Constants.colors.white};
    }
  }

  svg {

    a & {
      background: ${Constants.colors.background};
    }
  }

  ul li {
    margin: 1rem calc(0.5rem + 15px) 1rem 0;

    svg {
      position: absolute;
      top: 50%;
      right: -1.5rem;
      height: 14px;
      width: 14px;
      margin-top: -7px;
      stroke: ${Constants.colors.background};
    }

    a:hover,
    a:focus,
    a:active {

      svg line {
        stroke: ${Constants.colors.interactive};
      }
    }
  }
`;

export const ItemProminent = styled(Item)`
  font-size: 1.25em;

  @media ${Constants.devices.desktop} {
    font-size: 1.5em;
  }
`;

export const DigitalCollectionsLink = styled(Item)`
    position: relative;
    margin: 3rem 20px 1rem 0;
    padding: 0;
    font-family: ${Constants.fonts.sansSerif};
    font-size: 14px;
    font-weight: 300;
    text-transform: uppercase;
    letter-spacing: 0.1em;

    * {
      transition: none !important;
    }

    a {
      padding: 0 22px 0 0;

      &:after {
        content: none;
      }

      svg {
        height: 2.25em;
        width: 2.25em;
        fill: currentColor;
        // stroke: none;
        padding-right: 0.5em;
        transform: translateY(0.75em);
      }

      svg:nth-child(2) {
        position: absolute;
        top: 50%;
        right: 0;
        height: 14px;
        width: 14px;
        margin-top: -7px;
        padding: 0;
        stroke: ${Constants.colors.grayMedium};
      }

      &:hover,
      &:focus,
      &:active {
        color: ${Constants.colors.white};

        svg {
          fill: ${Constants.colors.interactiveLight};
        }
        svg:nth-child(2) {
          stroke: ${Constants.colors.white};
        }
      }
    }
`;