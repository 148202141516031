import styled from 'styled-components';
import { Link } from 'react-router-dom';
import * as Constants from '../../../constants';
import { hexToRgb } from "../../../utilities";

export const Nav = styled.nav`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-template-areas: 
    "previousArticle      nextArticle"
    "transcriptScanToggle transcriptScanToggle";
  grid-gap: 1em;
  align-items: center;
  margin: 0 auto;
  width: 100%;

  @media ${Constants.devices.tablet} {
    display: grid;
    grid-template-columns: 1fr 250px 1fr;
    grid-template-rows: auto;
    grid-template-areas: "previousArticle   transcriptScanToggle    nextArticle";
    grid-column-gap: 1em;
    align-items: center;
    margin: 0 auto;
    width: 100%;
  }
`;

export const PreviousArticleLink = styled(Link)`
  position: relative;
  grid-area: previousArticle;
  text-decoration: none;
  gap: 0.5em;
  padding: 0 0 0 1.5rem;
  align-items: center;
  color: ${Constants.colors.interactive};
  line-height: 1.3;
  font-family: sans-serif;
  font-size: 0.9rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 300;
  text-align: left;
  overflow: hidden;

  svg {
    position: absolute;
    top: 50%;
    left: 0;
    height: 1.25em;
    width: 1.25em;
    fill: none;
    stroke: ${Constants.colors.interactive};
    stroke-width: 60;
    stroke-linejoin: miter;
    transform: translateY(-50%);
  }
`;

export const NextArticleLink = styled(PreviousArticleLink)`
  grid-area: nextArticle;
  padding: 0 1.5rem 0 0;
  text-align: right;

  svg {
    left: auto;
    right: 0;
  }
`;

export const TranscriptScanToggle = styled.div`
  grid-area: transcriptScanToggle;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  box-shadow: 0px 5px 5px -2px rgba(${hexToRgb(Constants.colors.black)}, 0.1);
  z-index: 999999999;

  > * {
    flex-grow: 1;
    height: 100%;

    input[type=radio] {
      display: none;
  
      ~label {
        color: black;
        background: #fff;
      }
  
      &:checked~label {
        background: #36c;
        color: #fff;
      }
    }
  
    label {
      cursor: pointer;
      display: block;
      padding: 10px;
    }
  }
`;