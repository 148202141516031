import Issues from '../../data/issues.json';
import { getDayOfWeekName, getMonthName } from "../../utilities"
import * as Styled from "./styled";
import Download from "../Icons/Download";

const TEI = () => {
  return (
    <Styled.Text>
      <h2>Download the TEI P5 XML Files</h2>
      <p>The transcriptions of the <cite>Daily Dispatch</cite> are marked up using the TEI P5 XML standard, which contains rich semantic markup like the names of people and places and data about dates that is missing in HTML and thus on the HTML files viewable in this digital collection.</p>

      <Styled.DownloadButton href="/static/DispatchTEI.zip" download>
        <Download />Download All Dispatch TEI Files (~92MB)
      </Styled.DownloadButton>
      
      <p>You can also access and download the TEI marked-up files for any individual issue:</p>

      <Styled.DownloadList>
        {Issues.map((issue, index) => (
          <li key={`${issue.year}-${issue.month}-${issue.day}`}>
            <a href={`/static/DispatchXML/${issue.filename}`} download>
              {`${getDayOfWeekName(issue.year, issue.month, issue.day)}, ${getMonthName(issue.month)} ${issue.day}, ${issue.year}`}
            </a>
          </li>
        ))}
      </Styled.DownloadList>
    </Styled.Text>
  );
};

export default TEI;
