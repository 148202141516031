import styled from 'styled-components';
import * as Constants from '../../../constants';

export const Article = styled.div`
  grid-area: articles;
  overflow-y: auto;
  overflow-x: visible;
  padding-top: 1rem;
  padding-bottom: 3rem;
  font-size: 16px;
  z-index: 1000;
  scroll-behavior: smooth;
  font-family: ${Constants.fonts.serif};
  margin: 0 auto;
  line-height: 1.7;
  padding-bottom: 3rem;

  @media ${Constants.devices.tablet} {
    grid-row: 3/4;
    margin-top: 0;
  }

/* class styles in the html */

  h2 {
    margin: 0;
    line-height: 45px;
    text-align: center;
    font-size: 1.5rem;

    p {
      text-align: left;
      font-size: 16px;
      font-weight: 300;
      line-height: 1.7;
    }
  }

  mark.italics,
  mark.smallcaps {
    background-color: transparent;
  }

  .byline {
    text-transform: uppercase;
    font-size: 0.9em;
  }

  .opener {
    text-align: right;
    padding-right: 20px;
  }

  .italics {
    font-style: italic;
  }

  .smallcaps {
    font-variant: small-caps;
  }
`;
