import { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { useDimensions } from "../../hooks";
import Menu from "./Menu";
import Toggle from "./Toggle/Index";

const MenuAndToggle = () => {
  const { media } = useDimensions();
  const { pathname } = useLocation();
  const [isOpen, setIsOpen] = useState(media === "desktop");
  const currentMedia = useRef(media);
  const currentPathname = useRef(pathname);

  useEffect(() => {
    if (media !== currentMedia.current) {
      if (media === "desktop" && !isOpen) {
        setIsOpen(true);
      } else if (currentMedia.current === "desktop" && isOpen) {
        setIsOpen(false);
      }
      currentMedia.current = media;
    }
  }, [media]);

  // on mobile and tablets, close the menu if the URL is selected
  useEffect(() => {
    if (currentPathname.current !== pathname && media !== 'desktop') {
      setIsOpen(false);
      currentPathname.current = pathname;
    }
  }, [pathname, media]);


  return (
    <>
      {media !== "desktop" && <Toggle isMenuOpen={isOpen} setIsOpen={setIsOpen} />}
      {isOpen && <Menu />}
    </>
  );
};

export default MenuAndToggle;
