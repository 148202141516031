import styled from "styled-components";
import { NavLink } from "react-router-dom";
import * as Constants from "../../../constants";
import type { Month } from '../../../index.d';
import { hexToRgb } from "../../../utilities";

const width_of_month = 218;
const yearPadding = 30;
const gap_between_months = 20;
const getYearWidth = (num_months: number, include_padding?: boolean) => {
  // formula is width_of_month (218) * number_of_months + gap_between_months (20) * (number_of_months - 1) + padding (30) * 2
  return width_of_month * num_months + 20 * (num_months - 1) + (include_padding ? yearPadding * 2 : 0);
}

export const YearLink = styled(NavLink)`
  text-decoration: none;
  border-bottom: 2px solid transparent;
  text-transform: uppercase;

  &.active {
    border-color: ${Constants.colors.interactive};
  }
`;

export const Year = styled.div < { year: number; months: Month[] }>`
  margin: 0 auto;
  display: flex;
  gap: ${gap_between_months}px;
  justify-content: ${p => !p.months.includes(1) ? 'flex-end' : 'flex-start'};
  align-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  padding: ${yearPadding}px;
  // background-color: ${p => p.year % 2 === 0 ? 'transparent' : 'rgba(0,0,0,0.075)'};

  &:not(:first-of-type) {
    margin-top: 2rem;
  }

  // each calender is 218 px wide with a gap of 20 between them; add media queries to size the container based on the screen width
  width: ${width_of_month}px;

  // phone
  // 456px
  // formula is width_of_month (218) * number_of_months + gap_between_months (20) * (number_of_months - 1) + padding (30) * 2
  @media (min-width: ${getYearWidth(2, true)}px) {
    width: ${getYearWidth(2)}px;
  }
  // tablet
  // 694px
  @media (min-width: ${getYearWidth(3, true)}px) {
    width: ${getYearWidth(3)}px;
  }

  // desktop
  @media ${Constants.devices.desktop} {
    width: ${getYearWidth(2)}px;
  }

  // when desktop main container gets to width for three months—500 is the width of the sidebar/menu
  @media (min-width: ${getYearWidth(3, true) + 500}px) {
    width: ${getYearWidth(3)}px;
  }
`;

export const Years = styled.nav`
  display: flex;
  gap: 0;
  justify-content: center;
  // margin-bottom: 1rem;
  border-bottom: 1px solid ${Constants.colors.grayMedium};

  a {
    padding: 0.5em;
    color: ${Constants.colors.interactive};
    background-color: rgba(${hexToRgb(Constants.colors.interactiveLight)}, 0);
    border: none;

    @media ${Constants.devices.tablet} {
      padding: 0.5em 1em;
    }

    &:hover {
      background-color: rgba(${hexToRgb(Constants.colors.interactiveLight)}, 0.4);
    }

    &.active {
      color: ${Constants.colors.white};
      background-color: rgba(${hexToRgb(Constants.colors.interactive)}, 1);
    }
  }
`;
