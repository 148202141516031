import styled from "styled-components";
import * as Constants from "../../constants";
import { hexToRgb } from "../../utilities";

export const Calendar = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 30px);
  grid-gap: 1px;
  align-items: center;
  // border: 1px solid ${Constants.colors.grayMedium};
  background-color: ${Constants.colors.white};
  box-shadow: 0px 5px 5px -2px rgba(${hexToRgb(Constants.colors.black)}, 0.1);
  transition: background-color 300ms;
`;

export const Date = styled.div`
    text-align: center;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${Constants.colors.grayMedium};

    a {
      display: flex;
      width: 100%;
      height: 100%;
      align-items: center;
      justify-content: center;
      color: ${Constants.colors.interactive};
      text-decoration: none;
      background-color: rgba(${hexToRgb(Constants.colors.interactiveLight)}, 0.4);

      &:hover {
        background-color: ${Constants.colors.interactive};
        color: ${Constants.colors.white};
      }
    }
`;

export const DayOfWeek = styled(Date)`
    color: currentColor;
`;

export const Month = styled(Date)`
  grid-column: 1 / span 7;
  color: ${Constants.colors.white};
  background-color: ${Constants.colors.grayDark};
`;
