export const colors = {
  black: '#000000',
  white: '#ffffff',
  text: '#3e3e3d',
  textLight: '#6f6f6d',
  background: '#f0f0eb',
  grayLight: '#f0f0eb',
  grayMedium: '#c0c0c0',
  grayDark: '#656563',
  interactive: '#3366CC',
  interactiveLight: '#a3c1fd',
  interactiveDark: '#2952a3',
  yellow: '#FFFF8F',
}

export const fonts = {
  serif: "'Roboto Slab', sans-serif",
  sansSerif: "'Roboto', serif",
  slabSerif: "'Abril Fatface', serif",
}

export const componentDimensions = {
  masthead: {
    height: {
      mobile: 35,
      tablet: 50,
      desktop: 100,
    },
  },
  menuToggle: {
    width: {
      mobile: 50,
      tablet: 50,
    },
    height: 50
  }
};

export const sizes = {
  mobile: 480,
  tablet: 600,
  desktop: 1000,
  desktop2: 1440,
}

export const devices = {
  mobile: `(min-width: ${sizes.mobile}px)`,
  tablet: `(min-width: ${sizes.tablet}px)`,
  desktop: `(min-width: ${sizes.desktop}px)`,
  desktop2: `(min-width: ${sizes.desktop2}px)`,
}