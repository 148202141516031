import styled from 'styled-components';
import * as Constants from '../../../constants';
import { hexToRgb } from "../../../utilities";

export const Form = styled.form`
  grid-area: search-nav;
  position: relative;
  display: flex;
  max-width: min(900px, 95vw);
  align-items: center;
  text-align: center;

  * {
    font-family: ${Constants.fonts.sansSerif};
  }

  ::placeholder {
    color: rgba(${hexToRgb(Constants.colors.text)}, 0.5);
    opacity: 1;
  }

  input {
    width: 100%;
    padding: 0.75em 1em 0.75em 2em;
    color: ${Constants.colors.interactive};
    font-size: 1.5em;
    background-color: rgba(${hexToRgb(Constants.colors.white)}, 0.5);
    border: 1px solid ${Constants.colors.grayMedium};
    outline: none;

    &:focus {
      color: ${Constants.colors.interactive};
      background-color: rgba(${hexToRgb(Constants.colors.white)}, 1);
      border-color: transparent;
      box-shadow: 0 0 8px 2px rgba(${hexToRgb(Constants.colors.interactiveLight)}, 0.6);

      ~ div {
        opacity: 1;
  
        svg {
          fill: ${Constants.colors.interactive};
        }
      }
    }

    ~ a {
      margin-left: 1em;
      color: ${Constants.colors.text};
      font-size: 0.9em;
      text-decoration: none;

      &:hover {
        color: ${Constants.colors.interactive};
      }
    }
  }
`;

export const SearchIcon = styled.div`
  position: absolute;
  top: 50%;
  left: 0;
  height: 1.5em;
  width: 1.5em;
  transform: translate(0.75em,-0.75em);
  opacity: 0.5;

  svg {
    fill: currentColor;
  }
`;