import React from "react";
import { Link } from "react-router-dom";
import * as Styled from "./styled";

const About = () => {
  return (
    <Styled.Text>
      <h2>Project History and Technical Information</h2>
      <p>
        The project team secured permission from{" "}
        <a href="http://www.proquest.com/" target="_blank" rel="noopener noreferrer">
          ProQuest
        </a>{" "}
        to digitize their microfilm edition of the <i>Richmond Daily Dispatch</i> from November 1860 through December 1865. After their purchase the microfilm reels were sent to the imaging and rekeying vendor, Digital Divide Data, to be scanned, hand-keyed, and encoded as XML documents to the TEI P4 specification. Page images were scanned at a resolution of 600 dpi and saved as 8-bit grayscale
        TIFF images.
      </p>
      <p>
        The XML files for all of the newspapers were individually checked upon receipt from the vendor, and were subsequently transferred to the{" "}
        <a href="http://www.perseus.tufts.edu/" target="_blank" rel="noopener noreferrer">
          Perseus Project
        </a>{" "}
        for named entity identification and encoding. The resulting files had undergone major changes which necessitated the creation of a modified Document Type Definition (DTD) based on the TEI P4 specification, in order for them to be successfully ingested and indexed by XPAT, the indexing engine of the University of Michigan's Digital Library Production Service's{" "}
        <a href="http://dlxs.org/" target="_blank" rel="noopener noreferrer">
          DLXS
        </a>{" "}
        software suite. DLXS is a suite of tools which supports XML and Unicode, and allows for the processing of large and highly-structured texts. The system requires that all documents conform to a certain DTD style in order to be processed by the indexing engine. DLXS requires a UNIX environment, and the University of Richmond installed Red Hat Enterprise Linux on its dedicated server, as well
        as Apache web server, a MySQL database and Perl 5.8.0.
      </p>
      <p>JPEG 2000 images were created from the TIFF format images received from the vendor. These images reside on the project server, and are converted on the fly into the JPEG format when viewed by users.</p>
      <p>In all, the repository contains 1,384 indexed and searchable issues of the Richmond Daily Dispatch and 4,051 JPEG 2000 image files. The original page images in the TIFF format are archived on a separate server.</p>
      <p>
        <Link to="/background">Detailed documentation about the original project</Link> is also available.
      </p>
      <p>
        In 2015 a new version of the site was released. Built on open source tools (the <a href="http://exist-db.org/exist/apps/homepage/index.html" target="_blank" rel="noopener noreferrer">eXist native-XML database</a> and the <a href="http://sourceforge.net/projects/djatoka/" target="_blank" rel="noopener noreferrer">Djatoka JPEG-2000 image viewer</a>) and a customized web interface designed by <a href="http://communications.richmond.edu/" target="_blank" rel="noopener noreferrer">University Communications</a>, the
        new site enables direct searching of TEI P5 XML data.
      </p>
      <p>In 2024 a third version—this version—was developed and released. The TEI P5 XML files have been transpiled into static HTML files. The search engine now uses <a href="https://solr.apache.org/" target="_blank" rel="noopener noreferrer">Solr</a> instead of eXist. The web application was redeveloped using the React framework.</p>

      <h2>Original Project Personnel (2003-2007)</h2>
      <h4>Grant Proposal</h4>
      <ul>
        <li>Katherine Monday, Vice President for Information Services, University of Richmond</li>
        <li>Patricia A. Schoknecht, Director, Information Technology, Wagner College</li>
      </ul>
      <h4>Implementation Team</h4>
      <ul>
        <li>Rachel Frick, Head, Bibliographic Access Services, University of Richmond</li>
        <li>James Gwin, Head, Collection Development and Special Collections, University of Richmond</li>
        <li>Chris Kemp, Head, Digital Initiatives, University of Richmond</li>
        <li>Dr. Robert Kenzer, Professor of History and American Studies, University of Richmond</li>
        <li>Rick Neal, Library Systems Manager, University of Richmond </li>
        <li>James Rettig, Primary Investigator and University Librarian, University of Richmond</li>
        <li>Dr. Andrew Rouner, Director, Digital Library Services, Washington University</li>
        <li>Nancy Woodall, Head, Library Systems, University of Richmond</li>
      </ul>
      <h4>Perseus Project Personnel</h4>
      <ul>
        <li>Gregory Crane, Editor-in-Chief, Perseus Project, Tufts University</li>
        <li>Alison Jones, Research Coordinator, Perseus Project, Tufts University</li>
        <li>Adrian Packel, Programmer, Perseus Project, Tufts University</li>
        <li>Gabe Weaver, Programmer, Perseus Project, Tufts University</li>
      </ul>
      <h4>Metadata Consultant</h4>
      <ul>
        <li>
          Kim Tryka, Project Manager, <em>The South</em>, University of Virginia
        </li>
      </ul>
      <h4>Technical Support</h4>
      <ul>
        <li>Bob Littlepage, System Administrator, University of Richmond</li>
        <li>Leigh McDonald, Head, Cataloging and Metadata Services, University of Richmond</li>
        <li>Sue McGinnis, Director of Academic and Administrative Technology, Virginia Commonwealth University School of Nursing</li>
        <li>Sasko Stefanovski, Senior System Administrator, University of Richmond</li>
      </ul>
      <h4>Data Verification</h4>
      <ul>
        <li>Leslie Byrd, Metadata Editor, University of Richmond</li>
        <li>Catherine Clements, Cataloging Librarian, University of Richmond</li>
        <li>Grant Dickie, Metadata Editor, University of Richmond</li>
        <li>Greg Donahue, Metadata Editor, University of Richmond</li>
        <li>Gabrielle Henshaw, Cataloging Assistant, University of Richmond</li>
        <li>Karen Owens, Metadata Editor, University of Richmond</li>
        <li>Jeff Peil, Metadata Editor, University of Richmond</li>
      </ul>
      <h2>Phase Two Personnel (2012-2015)</h2>
      <ul>
        <li>Joedy Felts, User Interface Manager, University of Richmond</li>
        <li>David Finton, Programmer/Developer</li>
        <li>Chris Kemp, Head of Discovery, Technology, and Publishing, University of Richmond</li>
        <li>Dan McCreary, Consultant</li>
        <li>Kirsten McKinney, Graphic Designer, University of Richmond</li>
        <li>Eric Palmer, Director of Web Services, University of Richmond</li>
        <li>Kailin Shi, Programmer/Developer</li>
        <li>Claudius Teodorescu, Programmer/Developer</li>
        <li>Chris Wallace, Programmer/Developer</li>
        <li>Michael Weigle, Metadata Editor, University of Richmond</li>
        <li>Louis Zhou, Metadata Editor, University of Richmond</li>
      </ul>
      <h2>Phase Three Personnel (2024)</h2>
      <ul>
        <li>Chad Devers, Developer, University of Richmond</li>
        <li>Robert K. Nelson, Head of Digital Engagement, University of Richmond</li>
      </ul>

      <h2>Project Funding and Partners</h2>
      <Styled.Funders>
        <a href="http://www.imls.gov" target="_blank" rel="noopener noreferrer">
          <img src="../static/imlslogo.jpg" alt="IMLS logo" />
        </a>

        <a href="http://www.perseus.tufts.edu/" target="_blank" rel="noopener noreferrer">
          <img src="../static/perseus.gif" alt="Perseus Logo" />
        </a>

        <a href="http://www.proquest.com/" target="_blank" rel="noopener noreferrer">
          <img src="../static/proquest.gif" alt="Proquest Logo" />
        </a>

        <a href="http://www.vcdh.virginia.edu/" target="_blank" rel="noopener noreferrer"><img src="../static/vcdh.gif" alt="Virginia Center for Digital History Logo" /></a>
      </Styled.Funders>
    </Styled.Text>
  );
};

export default About;
