import * as Styled from "./styled";

const Background = () => {
  return (
    <Styled.Text>
      <p>In 2003 the Institute of Museum and Library Services awarded a National Leadership Grant to the University of Richmond, Tufts University, and the Virginia Center for Digital History for the proposal to build a testbed of Civil War-era newspapers. The documentation available from this page provides background and administrative information about the project.</p>

      <h2>Background Information</h2>
      <p>The Background Information page provides access to development and planning documentation dating back to the original grant proposal. Also available are survey results and a task force report that influenced the course of the project.</p>

      <ul>
        <li>
          <a target="_blank" rel="noopener noreferrer" href="../docs/background/grantdescription.pdf">
            Project description from grant proposal
          </a>
        </li>
        <li>
          <a target="_blank" rel="noopener noreferrer" href="../docs/background/supportingsources.pdf">
            Potential supporting sources for the <i>Daily Dispatch</i>
          </a>
        </li>
        <li>
          <a target="_blank" rel="noopener noreferrer" href="../docs/background/DLTFfinalreport.pdf">
            Final report of UR's Digital Library Task Force
          </a>
        </li>
        <li>
          <a target="_blank" rel="noopener noreferrer" href="../docs/background/vendorspec.pdf">
            Vendor specifications for digitization and rekeying
          </a>
        </li>
        <li>
          <a target="_blank" rel="noopener noreferrer" href="../docs/background/styleguide.pdf">
            University of Richmond style guide for XML markup
          </a>
        </li>
        <li>
          <a target="_blank" rel="noopener noreferrer" href="../docs/background/surveyresults.pdf">
            Survey results from the Civil War Historian conference
          </a>
        </li>
      </ul>

      <h2>Academic Papers</h2>
      <p>The planning leading up to the grant and the production processes involved generated a large quantity of research and data. Some of this is reported in several academic papers, available here.</p>
      <ul>
        <li>
          <a target="_blank" rel="noopener noreferrer" href="../docs/papers/usesofnewspapers.pdf">
            The Many Uses of Newspapers, by Alison Jones, Tufts University
          </a>
        </li>
        <li>
          <a target="_blank" rel="noopener noreferrer" href="../docs/papers/yesterdaysnews.pdf">
            The Importance of "Yesterday's News," by Alison Jones, Tufts University
          </a>
        </li>
        <li>
          <a target="_blank" rel="noopener noreferrer" href="../docs/papers/virginiabanks.pdf">
            The Challenge of Virginia Banks, by Alison Jones and Gregory Crane, Tufts University
          </a>
        </li>
      </ul>

      <h2>Reports and Minutes</h2>
      <p>This section contains semi-annual reports required by IMLS, as well as the conference call minutes between the University of Richmond, the Perseus Project, and others.</p>
      <h3>Semi-Annual Reports</h3>
      <ul>
        <li>
          <a target="_blank" rel="noopener noreferrer" href="../docs/reports/imlsrept04-03.pdf">
            October 2003 - March 2004
          </a>
        </li>
        <li>
          <a target="_blank" rel="noopener noreferrer" href="../docs/reports/imlsrept04-09.pdf">
            April 2004 - September 2004
          </a>
        </li>
        <li>
          <a target="_blank" rel="noopener noreferrer" href="../docs/reports/imlsrept05-03.pdf">
            October 2004 - March 2005
          </a>
        </li>
        <li>
          <a target="_blank" rel="noopener noreferrer" href="../docs/reports/imlsrept05-09.pdf">
            April 2005 - September 2005
          </a>
        </li>
        <li>
          <a target="_blank" rel="noopener noreferrer" href="../docs/reports/imlsrept06-03.pdf">
            October 2005 - March 2006
          </a>
        </li>
        <li>
          <a target="_blank" rel="noopener noreferrer" href="../docs/reports/imlsrept06-09.pdf">
            April 2006 - September 2006
          </a>
        </li>
      </ul>
      <h3>Final Report</h3>
      <ul>
        <li>
          <a target="_blank" rel="noopener noreferrer" href="../docs/reports/imlsfinal.pdf">
            Final Report, October 2007
          </a>
        </li>
      </ul>
      <h3>Conference Call Minutes</h3>
      <ul>
        <li>
          <a target="_blank" rel="noopener noreferrer" href="../docs/minutes/CC04-02.pdf">
            February 2004
          </a>
        </li>
        <li>
          <a target="_blank" rel="noopener noreferrer" href="../docs/minutes/CC04-03.pdf">
            March 2004
          </a>
        </li>
        <li>
          <a target="_blank" rel="noopener noreferrer" href="../docs/minutes/CC04-04.pdf">
            April 2004
          </a>
        </li>
        <li>
          <a target="_blank" rel="noopener noreferrer" href="../docs/minutes/CC04-05.pdf">
            May 2004
          </a>
        </li>
        <li>
          <a target="_blank" rel="noopener noreferrer" href="../docs/minutes/CC04-06.pdf">
            June 2004
          </a>
        </li>
        <li>
          <a target="_blank" rel="noopener noreferrer" href="../docs/minutes/CC04-07.pdf">
            July 2004
          </a>
        </li>
        <li>
          <a target="_blank" rel="noopener noreferrer" href="../docs/minutes/CC04-08.pdf">
            August 2004
          </a>
        </li>
        <li>
          <a target="_blank" rel="noopener noreferrer" href="../docs/minutes/CC04-09.pdf">
            September 2004
          </a>
        </li>
        <li>
          <a target="_blank" rel="noopener noreferrer" href="../docs/minutes/CC04-10.pdf">
            October 2004
          </a>
        </li>
        <li>
          <a target="_blank" rel="noopener noreferrer" href="../docs/minutes/CC04-12.pdf">
            December 2004
          </a>
        </li>
        <li>
          <a target="_blank" rel="noopener noreferrer" href="../docs/minutes/CC05-02.pdf">
            February 2005
          </a>
        </li>
        <li>
          <a target="_blank" rel="noopener noreferrer" href="../docs/minutes/CC05-03.pdf">
            March 2005
          </a>
        </li>
        <li>
          <a target="_blank" rel="noopener noreferrer" href="../docs/minutes/CC05-10.pdf">
            May 2005
          </a>
        </li>
        <li>
          <a target="_blank" rel="noopener noreferrer" href="../docs/minutes/CC05-11.pdf">
            November 2005
          </a>
        </li>
        <li>
          <a target="_blank" rel="noopener noreferrer" href="../docs/minutes/CC05-12.pdf">
            December 2005
          </a>
        </li>
        <li>
          <a target="_blank" rel="noopener noreferrer" href="../docs/minutes/CC06-03.pdf">
            March 2006
          </a>
        </li>
        <li>
          <a target="_blank" rel="noopener noreferrer" href="../docs/minutes/CC06-04.pdf">
            April 2006
          </a>
        </li>
        <li>
          <a target="_blank" rel="noopener noreferrer" href="../docs/minutes/CC06-07.pdf">
            July 2006
          </a>
        </li>
        <li>
          <a target="_blank" rel="noopener noreferrer" href="../docs/minutes/CC06-12.pdf">
            December 2006
          </a>
        </li>
      </ul>
    </Styled.Text>
  );
};

export default Background;
