import { Link } from "react-router-dom";
import * as Styled from "./styled";
import Issues from '../../data/issues.json';

const ADate = ({ year, month, day }: { year: number; month: number; day: number }) => {
  const theDate = new Date(year, month - 1, day);
  const firstDayOfMonth = new Date(year, month - 1, 1).getDay();
  // get the day of the week
  const column = theDate.getDay() + 1;
  const row = Math.ceil((day + firstDayOfMonth) / 7) + 2;
  const exists = Issues.some(d => d.year === year && d.month === month && d.day === day);

  return (
    <Styled.Date
      style={{
        gridColumn: `${column} / span 1`,
        gridRow: `${row} / span 1`,
      }}
    >
      {exists ? <Link to={`/${year}/${month}/${day}`}>{day}</Link> : <>{day}</>}
    </Styled.Date>
  );
};

export default ADate;
