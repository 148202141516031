// from https://stackoverflow.com/questions/54738221/typescript-array-find-possibly-undefind
export function ensure<T>(argument: T | undefined | null, message: string = 'This value was promised to be there.'): T {
  if (argument === undefined || argument === null) {
    throw new TypeError(message);
  }

  return argument;
}

export const getKeys = <T extends {}>(o: T): Array<keyof T> => <Array<keyof T>>Object.keys(o);

// get the name of the month from the month number 
export const getMonthName = (month: number) => {
  const months = ["January", "February", "March", "April", "May", "June", "July", "August",
    "September", "October", "November", "December"];
  return months[month - 1];
}

// get the day of the week e.g. Sunday from the number
export const getDayOfWeekName = (dayOrYear: number, month?: number, day?: number) => {
  const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
  return (month && day) ? days[getDayOfWeek(dayOrYear, month, day)] : days[dayOrYear];
}

// get the day of the week from the year, month, and day
export const getDayOfWeek = (year: number, month: number, day: number) => {
  const date = new Date(year, month - 1, day);
  const dayOfWeek = date.getDay();
  return dayOfWeek;
}

/**
 * Takes a hex color and converts it to an rgb string
 * @param hex The hex color
 * @returns The corresponding rgb color
 */
export const hexToRgb = (hex: string) => {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex) as RegExpExecArray;
  const rgb = (result) ? {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16)
  } : {
      r: 125,
      g: 125,
    b: 125,
  };
  return `${rgb.r}, ${rgb.g}, ${rgb.b}`;
};
