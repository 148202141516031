import styled from "styled-components";
import { Link, NavLink } from "react-router-dom";
import type { Month } from '../../index';
import { Issue } from "../Issue/styled";
import * as Constants from "../../constants";
import { hexToRgb } from "../../utilities";

export const Landing = styled(Issue)`
  height: 100%;
  overflow-y: auto;

  @media ${Constants.devices.desktop} {
    height: 100vh;
    overflow: auto;
  }

  > p {
    max-width: 600px;
    margin: 20px auto;
  }

  h2 {
    margin-top: 3em;
    font-family: ${Constants.fonts.serif};
    text-align: center;

    a {
      color: $color-text;
      text-decoration: none;
    }
  }
`;

export const DescriptionAndBrowse = styled.div`
  grid-area: articles;
  padding-bottom: 3rem;
`;

export const YearLink = styled(NavLink)`
  text-decoration: none;
  border-bottom: 2px solid transparent;
  text-transform: uppercase;

  &.active {
    border-color: ${Constants.colors.interactive}
  }
`;

export const Year = styled.div < { year: number; months: Month[]}>`
  display: flex;
  gap: 20px;
  justify-content: ${p => !p.months.includes(1) ? 'flex-end' : 'flex-start'};
  align-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  padding: 40px;
  background-color: ${p => p.year % 2 === 0 ? '#fff' : 'transparent'};

  // each calender is 218 px wide with a gap of 20 between them; add media queries to size the container based on the screen width
  width: 218px;

  @media (min-width: ${218 * 2 + 20 * 1}px) {
    width: ${218 * 2 + 20 * 1}px;
  }
  @media (min-width: ${218 * 3 + 20 * 2}px) {
    width: ${218 * 3 + 20 * 2}px;
  }
`;

export const VolumeLink = styled(Link)`
  display: inline-block;
  text-align: center;
    margin: 0 0 10px 0;
    padding: 1rem;
    color: black;
    text-decoration: none;
    background-color: rgba(${hexToRgb(Constants.colors.white)}, 0.4);
    transition: background-color 300ms, box-shadow 1000ms;
    -webkit-box-shadow: 0px 5px 5px -2px rgba(${hexToRgb(Constants.colors.black)}, 0.1);
    -moz-box-shadow: 0px 5px 5px -2px rgba(${hexToRgb(Constants.colors.black)}, 0.1);
    box-shadow: 0px 5px 5px -2px rgba(${hexToRgb(Constants.colors.black)}, 0.1);

    &:hover,
    &:active {
      background-color: rgba(${hexToRgb(Constants.colors.white)}, 1);
      -webkit-box-shadow: 0px 5px 5px -2px rgba(${hexToRgb(Constants.colors.black)}, 0.025);
      -moz-box-shadow: 0px 5px 5px -2px rgba(${hexToRgb(Constants.colors.black)}, 0.025);
      box-shadow: 0px 5px 5px -2px rgba(${hexToRgb(Constants.colors.black)}, 0.025);
    }
`;

export const Years = styled.nav`
  display: flex;
  gap: 20px;
  justify-content: center;
  height: 50px;
`;
