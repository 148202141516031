import axios from 'axios';
import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { getDayOfWeekName, getMonthName } from '../../utilities';
import IssueHeader from '../Issue/Header/Index';
import Input from './Input/Index';
import Nav from './Nav';
import * as Styled from './styled';
import * as Types from './types';

const Search = () => {
  const { q, page: pageStr, year, month, day } = useParams();
  // the number or rows requested by the search
  const rows = 10;
  const page = (pageStr) ? parseInt(pageStr, 10) : 1;
  const start = rows * (page - 1);

  const [results, setResults] = useState<Types.Result[]>([]);
  const [numResults, setNumResults] = useState<number | undefined>(undefined);


  // run the search
  useEffect(() => {
    if (q) {
      axios.get<Types.SOLRData>(`https://digicol.richmond.edu:8983/solr/dispatch/select?start=${start}&rows=${rows}&hl.fl=text&hl=true&indent=true&q.op=OR&q=text%3A${q}${(year) ? `&fq=year:${year}` : ''}${(month) ? `&fq=month:${month}` : ''}${(day) ? `&fq=day:${day}` : ''}`, {
        auth: {
          username: 'websites',
          password: 'qhsknQP!X2^P*It7'
        }
      })
        .then(response => {
          if (response.data.response.docs?.length > 0) {
            const results = response.data.response.docs.map(d => {
              const { id } = d;
              const texts = response.data.highlighting[id].text;
              return {
                article_id: d.article_id,
                page: d.page,
                day: d.day,
                month: d.month,
                year: d.year,
                title: d.title,
                text: texts || [],
              }
            });
            setResults(results);
          } else {
            setResults([])
          }
          setNumResults(response.data.response.numFound);
        });
    }
  }, [q, start, year, month, day]);

  return (
    <Styled.SearchResults>
      {(year && month && day) && (
        <IssueHeader link={true} />
      )}
      
      <Input />

      {(numResults === 0) && (
        <Styled.NoResults>No matching search results</Styled.NoResults>
      )}

      <Styled.SearchResultsContainer>
        {!!(numResults && numResults > 0 && q) && (
          <Nav
            page={page}
            numResults={numResults}
            rows={rows}
            q={q}
          />
        )}
        {results.map(result => (
          <Styled.Results key={`results for ${result.year}-${result.month}-${result.day}-${result.page}-${result.article_id}`}>
            <Styled.Title>
              <Link to={`../../${result.year}/${result.month}/${result.day}/${result.page}/${result.article_id}`}>
                {`${getDayOfWeekName(result.year, result.month, result.day)}, ${getMonthName(result.month)} ${result.day}, ${result.year} ${(result.title) ? ` — ${result.title}` : ''}`}
              </Link>
            </Styled.Title>
            <ul>
              {result.text && result.text.map(text => (
                <Styled.Excerpt
                  dangerouslySetInnerHTML={{ __html: text }}
                  key={text}
                />
              ))}
            </ul>
          </Styled.Results>
        ))}

        {!!(numResults && numResults > 0 && q) && (
          <Nav
            page={page}
            numResults={numResults}
            rows={rows}
            q={q}
          />
        )}
      </Styled.SearchResultsContainer>
    </Styled.SearchResults>

  );
}

export default Search;