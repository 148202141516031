import { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import * as Styled from './styled';

const Article = () => {
  const { year: yearStr, month: monthStr, day: dayStr, article_id: article_idStr, page } = useParams();
  const year = parseInt(yearStr as string);
  const month = parseInt(monthStr as string);
  const day = parseInt(dayStr as string);
  const article_id = parseInt(article_idStr as string);

  const [articleHTML, setArticleHTML] = useState<string>();

  // get the article 
  useEffect(() => {
    axios.get<string>(`${process.env.PUBLIC_URL}/articles/${year}/${month}/${day}/${page}/${article_id}.html`)
      .then((response) => {
        setArticleHTML(response.data);
      });
  }, [year, month, day, page, article_id]);

  if (!articleHTML) {
    return null;
  }

  return (
    <Styled.Article dangerouslySetInnerHTML={{ __html: articleHTML }} />
  );
};

export default Article;