import styled from 'styled-components';
import * as Constants from '../../../constants';

export const Toggle = styled.div`
  grid-area: menutoggle;
  align-self: center;
  z-index: 500;
  justify-self: center;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: ${Constants.colors.text};
  
  * {
    cursor: pointer;
  }
`;