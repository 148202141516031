import { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { AppContext } from "./Contexts";
import About from "./components/Texts/About";
import Background from "./components/Texts/Background";
import Introduction from "./components/Texts/Introduction";
import TEI from './components/Texts/TEI';
import Menu from "./components/Menu/Index";
import ContactUs from "./components/ContactUs/Index";
import Header from "./components/Header/Index";
import Landing from "./components/Landing/Index";
import Issue from "./components/Issue/Index";
import Article from "./components/Article/Index";
import Search from "./components/Search/Index";
import type { Dimensions } from "./index.d";
import * as Styled from "./styled";

function App() {
  const calculateDimensions = () => {
    const { innerWidth, innerHeight } = window;
    const { clientWidth, clientHeight } = document.documentElement ? document.documentElement : { clientWidth: null, clientHeight: null };
    const width = clientWidth || innerWidth;
    const height = clientHeight || innerHeight;
    const media = (width < 600) ? "phone" : (width < 1000) ? "tablet-portrait" : "desktop";

    const dimensions: Dimensions = {
      width,
      height,
      media,
    };
    
    return dimensions;
  };

  const [dimensions, setDimensions] = useState(calculateDimensions());

  useEffect(() => {
    window.addEventListener("resize", () => setDimensions(calculateDimensions()));
    setDimensions(calculateDimensions());
  }, []);

  return (
    <AppContext.Provider value={{ ...dimensions }}>
      <Styled.GlobalStyle />
      <Styled.App>
        <BrowserRouter basename="/">
          <Header />
          <Menu />
          <Styled.Main>
            <Routes>
              <Route path="/" element={<Landing />} />
              <Route path="/index.html" element={<Landing />} />
              <Route path="search" element={<Search />} />
              <Route path="search/:q" element={<Search />} />
              <Route path="search/:q/:page" element={<Search />} />
              <Route path="search/:year/:month/:day/:q/" element={<Search />} />
              <Route path="search/:year/:month/:day/:q/:page" element={<Search />} />
              <Route path=":year" element={<Landing />} />
              <Route path=":year/:month/:day/" element={<Issue />} />
              <Route path=":year/:month/:day/:page/:article_id" element={<Article />} />
              <Route path="about" element={<About />} />
              <Route path="background" element={<Background />} />
              <Route path="download" element={<TEI />} />
              <Route path="introduction" element={<Introduction />} />
              <Route path="contactUs" element={<ContactUs />} />
            </Routes>
          </Styled.Main>
        </BrowserRouter>{" "}
      </Styled.App>{" "}
    </AppContext.Provider>
  );
}

export default App;
