import { useRef } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import * as Styled from './styled';
import SearchIcon from "../../Icons/SearchIcon";

const Search = ({placeholder}: {placeholder?: string}) => {
  const { q, year, month, day } = useParams();
  // the number or rows requested by the search

  const ref = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();

  const runSearch = (e: React.SyntheticEvent | null) => {
    if (e) {
      e.preventDefault();
    }
    if (ref.current?.value && ref.current.value.length > 1) {
      navigate((year && month && day) ? `/search/${year}/${month}/${day}/${ref.current.value}` : `/search/${ref.current.value}`);
    }
  }

  return (
    <Styled.Form onSubmit={runSearch}>
      <input
        type='text'
        ref={ref}
        placeholder={placeholder || 'Search'}
        defaultValue={q}
      />
      <Styled.SearchIcon><SearchIcon /></Styled.SearchIcon>
      {(q) && (
        <Link
          to={(year && month && day) ? `/${year}/${month}/${day}` : `..`}
        //relative={(year && month && day) ? 'route' : 'path'}
        onClick={() => { console.log('clicked');  }}
        >
          Clear
        </Link>
      )}
    </Styled.Form>
  );
}

export default Search;