import styled from 'styled-components';
import { Link } from 'react-router-dom';
import * as Constants from '../../../constants';

export const IssueHeader = styled.header`
  grid-area: header;
  display: grid;
  grid-template-columns: 1fr min-content 1fr;
  width: 100%;
  justify-items: center;
  align-items: center;
  grid-column-gap: 1em;
`;

export const Header = styled.h1`
  grid-column: 2 / span 1;
  grid-row: 1 / span 1;
  font-size: clamp(1.2rem, 2.5vw, 1.75rem);
  color: ${Constants.colors.grayDark};
  text-align: center;
  white-space: nowrap;

  a {
    color: ${Constants.colors.interactive};
  }
`;

export const UpLink = styled(Link)`
  grid-column: 1 / span 1;
  grid-row: 1 / span 1;
  justify-self: right;

  svg {
    height: 1.25em;
    width: 1.25em;
    fill: none;
    stroke: ${Constants.colors.interactive};
    stroke-width: 60;
    stroke-linejoin: miter;
    transform: translateY(20%);
  }
`;

export const Previous = UpLink;

export const Next = styled(UpLink)`
  grid-column: 3 / span 1;
  grid-row: 1 / span 1;
  justify-self: left;
`;