import React from "react";
import { useParams } from 'react-router-dom';
import { useIssue } from "../../../hooks";
import * as Styled from "./styled";

const Nav = ({ isTranscript, setIsTranscript }: { isTranscript: boolean; setIsTranscript: React.Dispatch<React.SetStateAction<boolean>> }) => {
  const { year: yearStr, month: monthStr, day: dayStr, article_id: article_idStr } = useParams();
  const year = parseInt(yearStr as string);
  const month = parseInt(monthStr as string);
  const day = parseInt(dayStr as string);
  const article_id = parseInt(article_idStr as string);

  const issue = useIssue(year, month, day);

  return (
    <Styled.Nav>


      <Styled.TranscriptScanToggle>
        <div>
          <input type="radio" id="tab1" name="tab-group" checked={isTranscript} />
          <label htmlFor="tab1" onClick={() => { setIsTranscript(true) }}>Transcript</label>
        </div>
        <div>
          <input type="radio" id="tab2" name="tab-group" checked={!isTranscript} />
          <label htmlFor="tab2" onClick={() => { setIsTranscript(false) }}>Page Image</label>
        </div>
      </Styled.TranscriptScanToggle>

      {(issue[article_id - 2]) && (
        <Styled.PreviousArticleLink to={`../../${issue[article_id - 2].page}/${article_id - 1}`} relative='path'><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 166.52 331.63">
          <path d="M165.82 331.28.35 165.82 165.82.35" />
        </svg> {issue[article_id - 2].title}</Styled.PreviousArticleLink>
      )}

      {(issue[article_id]) && (
        <Styled.NextArticleLink to={`../../${issue[article_id].page}/${article_id + 1}`} relative='path'>{issue[article_id].title} <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 166.52 331.63">
          <path d="m.35 331.28 165.47-165.46L.35.35" />
        </svg></Styled.NextArticleLink>
      )}
    </Styled.Nav>
  )
};

export default Nav;