import styled from "styled-components";
import * as Constants from "../../constants";

export const Header = styled.header`
  grid-area: masthead;
  display: flex;
  height: 100%;
  color: ${Constants.colors.background};
  font-family: ${Constants.fonts.slabSerif};
  text-align: left;
  text-transform: uppercase;
  background-color: ${Constants.colors.text};
`;

export const Title = styled.h1`
  display: inline-block;
  margin: 8px 0 1px 10px;
  color: ${Constants.colors.background};
  font-size: 20px;
  line-height: 1;
  letter-spacing: 0.05em;
  font-family: 'Abril Fatface', serif;

  @media ${Constants.devices.tablet} {
    margin: 0 10px;
    font-size: 32px;
    line-height: ${Constants.componentDimensions.masthead.height.tablet}px;
  }

  @media ${Constants.devices.desktop} {
    margin: 20px 1rem 0 1rem;
    line-height: 1;
  }
`;

export const Subtitle = styled.h2`
  display: none;
  margin: 1px 0 0 10px;
  color: ${Constants.colors.background};
  font-size: 20px !important;
  text-transform: uppercase;
  letter-spacing: 0.1em !important;
  opacity: 0.5;
  

  @media ${Constants.devices.tablet} {
    display: block;
    margin: 0 10px;
    font-size: 20px !important;
    line-height: ${Constants.componentDimensions.masthead.height.tablet}px;
  }

  @media ${Constants.devices.desktop} {
    margin: 3px 1rem 0 1rem;
    font-size: 20px !important;
    line-height: 1;
  }
`;
