import { useParams } from 'react-router-dom';
import { TileLayer } from 'react-leaflet';
import * as Styled from './styled';
import 'leaflet/dist/leaflet.css';

const ScanViewer = () => {
  const { year, month, day, page } = useParams();

  const url = `//s3.amazonaws.com/dsl-general/dispatch/${year}/${month}/${day}/${page}/{z}/{x}_{y}.png`;
  return (
    <Styled.Page
      center={[0, 0]}
      bounds={[[84, -180], [-0.7727930360076982 , -36.57576510160206]]}
      minZoom={1}
      id="page_Scan"
    >
      <TileLayer
        url={url}
        zIndex={1000}
        maxZoom={5}
        noWrap
        key={`page${1}`}
      />
    </Styled.Page>
  );
};

export default ScanViewer;
