import { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import { AppContext } from './Contexts';
import type { Issue } from './index.d';


export function useDimensions() {
  return useContext(AppContext); 
};


export const useIssue = (year: number, month: number, day: number) => {
  const [issue, setIssue] = useState<Issue>([]);
  useEffect(() => {
    axios.get<Issue>(`${process.env.PUBLIC_URL}/issues/${year}/${month}/${day}.json`)
      .then(response => {
        if (response?.data) {
          setIssue(response.data);
        }
      })
      .catch(err => { });
  }, [year, month, day]);
  return issue;
}