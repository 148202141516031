import styled from 'styled-components';
import * as Constants from '../../constants';
import { hexToRgb } from "../../utilities";

export const Container = styled.div`
  line-height: 1.7;
  max-width: min(95%, 600px);
  margin: 0 auto 2rem auto;

  h2 {
    text-align: center;
  }
`;

export const ContactUsForm = styled.form`
  display: grid;
  grid-template-columns: 100%;
  row-gap: 20px;
  column-gap: 20px;

  @media ${Constants.devices.tablet} {
    grid-template-columns: 100px auto;
  }

  label {
    margin-bottom: -10px;

    @media ${Constants.devices.tablet} {
      text-align: right;
    }
  }

  textarea {
    height: 200px;
  }

  input, textarea {
    padding: 0.5rem;
    background-color: rgba(${hexToRgb(Constants.colors.white)}, 0.5);
    border: 1px solid ${Constants.colors.grayMedium};
    outline: none;

    &:focus {
      color: ${Constants.colors.interactive};
      background-color: rgba(${hexToRgb(Constants.colors.white)}, 1);
      border-color: transparent;
      box-shadow: 0 0 8px 2px rgba(${hexToRgb(Constants.colors.interactiveLight)}, 0.6);

      svg {
        fill: ${Constants.colors.interactive};
      }
    }
  }

  input[type=submit] {
    padding: 0.75rem;
    color: ${Constants.colors.white};
    font-weight: 600;
    background-color: ${Constants.colors.interactive};
    border: none;
    cursor: pointer;
  
    &:hover {
      background-color: ${Constants.colors.interactiveDark};
    }
  }
`;

export const SubmitButton = styled.input`
  padding: 0.75rem;
  color: ${Constants.colors.white};
  font-family: ${Constants.fonts.sansSerif};
  font-size: 0.8em;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 0.1em;
  background-color: ${Constants.colors.interactive};
  border: none;
  cursor: pointer;  

  &:hover {
    background-color: ${Constants.colors.interactiveDark};
  }

  @media ${Constants.devices.tablet} {
    grid-column: 2 / span 1;
  }
`;