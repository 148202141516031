import ADate from './Date';
import { getMonthName } from '../../utilities';
import * as Styled from './styled';

const Calendar = ({ year, month }: { year: number, month: number }) => {
  // get number of days in the month);
  const daysInMonth = new Date(year, month, 0).getDate();
  const dates = Array.from(Array(daysInMonth).keys()).map(d => d + 1);

  return (
    <Styled.Calendar>
      <Styled.Month>
        {`${getMonthName(month)} ${year}`}
      </Styled.Month>
      {['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'].map(dayofWeek => (
        <Styled.DayOfWeek key={dayofWeek}>{dayofWeek.substring(0, 1)}</Styled.DayOfWeek>
      ))}

      {dates.map(day => (
        <ADate
          year={year}
          month={month}
          day={day}
          key={`${year}-${month}-${day}`}
        />
      ))}
    </Styled.Calendar>
  )
};

export default Calendar;
