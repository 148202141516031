import Input from "../Search/Input/Index";
import * as Styled from "./styled";
import Calendar from "./Calendar/Index";

const Landing = () => {
  return (
    <Styled.Landing>
      <Input placeholder="Search the Daily Dispatch" />

      <Styled.DescriptionAndBrowse>
        <h2>THE RISE AND FALL OF A CAPITAL</h2>
        <p>
          On the eve of the Civil War, Richmond, Virginia's capital since 1780, bustled with the commerce, politics, and enterprise of an active southern city. The Dispatch, one of the city's assorted newspapers, reported on local and world events, creating snapshots of daily life. Both the city and newspaper would experience major changes by early 1865, and both would be left in ruins by April of
          that year. Using the transcriptions of Richmond's non-partisan newspaper, discover how dramatically the city, and thus the people and fortunes of the South, changed during the course of the war.
        </p>

        <Calendar />
      </Styled.DescriptionAndBrowse>
    </Styled.Landing>
  );
};

export default Landing;
